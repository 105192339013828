body {
  margin: 0;
  font-family: "Poppins", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

.btn {
  border-radius: 0.5rem;
}

.btn-primary {
  border-color: #5571af;
  background: #5571af;
  color: #ffffff;
}

.btn-primary:hover,
.btn-primary:focus,
.btn-primary:active {
  border-color: #202a41;
  background: #202a41;
}

a.maillink {
  text-decoration: none;
  color: #324f94;
  transition: all 0.3 ease-in;
}

a.maillink:hover {
  color: #113997;
  border-bottom:1px solid #1d459f
}

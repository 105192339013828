/*--------------------------------------------------------------
# Hero Section
--------------------------------------------------------------*/
#hero {
  width: 100%;
  position: relative;
  display: flex;
  height: 80vh;
  align-items: center;
  justify-content: center;
}

#hero:before {
  content: "";
  position: absolute;
  bottom: 0;
  top: 0;
  left: 0;
  right: 0;
}

#hero h1 {
  margin: 0 0 20px 0;
  font-size: 48px;
  font-weight: 700;
  line-height: 56px;
  color: #5571af;
}

#hero h1 span {
  color: #5571af;
  border-bottom: 4px solid #243250;
}

#hero h2 {
  color: #5571af;
  margin-bottom: 40px;
  font-size: 24px;
}

#hero .animated {
  animation: up-down 2s ease-in-out infinite alternate-reverse both;
}

@media (min-width: 1024px) {
  #hero {
    background-attachment: fixed;
  }
}

@media (max-width: 991px) {
  #hero {
    padding-top: 80px;
  }

  #hero .animated {
    animation: none;
  }

  #hero .hero-img {
    text-align: center;
  }

  #hero .hero-img img {
    max-width: 50%;
  }

  #hero h1 {
    font-size: 28px;
    line-height: 32px;
    margin-bottom: 10px;
  }

  #hero h2 {
    font-size: 18px;
    line-height: 24px;
    margin-bottom: 30px;
  }
}

@media (max-width: 575px) {
  #hero .hero-img img {
    width: 80%;
  }
}

@keyframes up-down {
  0% {
    transform: translateY(10px);
  }

  100% {
    transform: translateY(-10px);
  }
}

.bg-theme-color {
  background-color: #5571af;
  color: #ffffff;
}

.service-card {
  text-align: center;
  background: transparent;
  border: none;
  width: 100%;
  margin-bottom: 1.5rem;
  transition: all 0.3s ease-in-out;
  border-radius: 2rem;
}

.service-card-body {
  padding: 0.5rem;
  text-align: center;
}

.service-card-body .service-card-text {
  text-align: justify;
}

.img-container {
  height: 350px;
  transition: all 0.3s ease-in-out;
}

.service-card:hover .img-container {
  padding: 0.2rem;
}

.card-image {
  border-radius: 2rem;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center 0%;
}

.service-card:hover .img-container .card-image {
  box-shadow: #364d81 0px 3px 10px 0px;
}

.service-card-title {
  font-size: 1.5rem;
  font-weight: 600;
  margin-top: 1rem;
  margin-bottom: 1rem;
  font-family: "Roboto", sans-serif;
  color:#ffffff;
}

.bg-theme-color {
  background-color: #5571af;
  color: #ffffff;
}

.objective-card {  
  background: transparent;
  border: none;
  width: 100%;
  margin-bottom: 1rem;
  transition: all 0.3s ease-in-out;
  border-radius: 2rem;
}

.objective-card-body {
  padding: 0.5rem;  
}

.objective-card-body .objective-card-text {
  text-align: justify;
}

.objective-card:hover .img-container {
  padding: 0.2rem;
}

.objective-card-title {
  font-size: 1.5rem;
  font-weight: 600;
  margin-top: 1rem;
  margin-bottom: 1rem;
  font-family: "Roboto", sans-serif;
  color:#252525;
}

.mv-container {
  background-color: #5571af;
  color: #ffffff;
}

p.statement-title-color {
  color: #ffffff;
}

.statement-card h4 {
  margin-bottom: 1rem;
}

.justify-text {
  text-align: justify;
}

.ps-md-large {
  padding-left: 4rem;
}

.pe-md-large {
  padding-right: 4rem;
}

@media (max-width: 767px) {
  .pe-md-large {
    padding-right: 0rem;
  }
  .ps-md-large {
    padding-left: 0rem;
  }
}
